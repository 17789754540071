Alpine.data("tabCard", () => {
  return {
    tabActive: false,
    init() {
      this.$nextTick(() => {
        this.onChangedEvent(); // Initially ensure its loaded
        this.$watch("changedAt", () => {
          this.onChangedEvent();
        });
      });
    },
    onChangedEvent() {
      this.tabActive = this.isActiveTab(this.$el);
    },
  };
});
