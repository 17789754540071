import "./tab/card";

const TabIdPrefix = "-content-";

Alpine.data("tab", () => {
  return {
    tabSelected: 1,
    tabId: null,
    activeButton: null,
    tabCards: null,
    disabledAt: null,
    tabButtons: [],
    tabButtonsHidden: false,
    changedAt: null,
    interval: null,
    currentViewportWidth: 0,
    init() {
      this.tabId = this.$id("tabs");

      this.tabCards = this.$el.getElementsByClassName("tab-card");

      this.updateDisabledAt(this.$el);

      this.tabButtons = Array.from(this.tabCards);

      this.tabButtonsHidden = this.isDisabled();

      // Temp ghetto fix
      this.interval = setInterval(() => {
        this.windowResized();
      }, 500);
    },
    updateDisabledAt(element) {
      this.disabledAt =
        breakpoints[element.getAttribute("disabled-at")] || null;
    },
    checkButtonIsInitiallyActive(tabButton, tabCard) {
      this.$nextTick(() => {
        if (tabCard.getAttribute("active")) {
          this.setActiveTab(tabButton);
          this.refreshTabs();
        }
      });
    },
    windowResized() {
      this.updateActiveMarker(this.activeButton);

      this.tabButtonsHidden = this.isDisabled();

      this.refreshTabs();
    },
    isDisabled() {
      return this.disabledAt === null
        ? false
        : window.innerWidth > this.disabledAt;
    },
    updateActiveMarker(tabButton) {
      this.activeButton = tabButton;

      this.$refs.tabMarker.style.width = tabButton.offsetWidth + "px";
      this.$refs.tabMarker.style.height = tabButton.offsetHeight + "px";
      this.$refs.tabMarker.style.left = tabButton.offsetLeft + "px";
    },
    setActiveTab(tabButton) {
      this.activeButton = tabButton;

      this.tabSelected = tabButton.id.replace(this.tabId + "-", "");

      this.updateActiveMarker(this.activeButton);

      this.refreshTabs();
    },
    refreshTabs() {
      this.changedAt = Date.now(); // Forces the tabs to check if the state has changed
    },
    isActiveTab(tabContent) {
      return (
        this.isDisabled() ||
        this.tabSelected === tabContent.id.replace(this.tabId + TabIdPrefix, "")
      );
    },
  };
});
