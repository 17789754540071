/* global Livewire */
document.addEventListener("livewire:init", () => {
    Alpine.data("pageNotifications", (userId) => {
        return {
            /**
             * Array of current displaying exceptions
             */
            notifications: [],

            /**
             * Initialize the Alpine Component
             *
             * Register to websocket User Events
             * and also for Livewire exceptions
             */
            init() {
                this.registerEchoUserNotifications();

                this.registerLivewireHookExceptionNotifications();
            },

            /**
             * Register notifications for the user be displayed on this page
             */
            registerEchoUserNotifications() {
                if(userId) {
                    Echo.private(`user.${userId}`).notification(
                        (notification) => this.add(notification)
                    );
                } else {
                    logger.info(null, "The notification for the users cannot be displayed since the user ID isn't provided on this page.");
                }
            },

            /**
             * Register Livewire Hook Exceptions as notifications
             */
            registerLivewireHookExceptionNotifications() {
                Livewire.hook("request", ({ fail }) => {
                    fail(({ status, content, preventDefault }) => {
                        if (!content.includes("</html>")) {
                            this.add({
                                status: status,
                                content: content,
                                type: 'Exception'
                            });

                            preventDefault();
                        }
                    });
                });
            },

            /**
             * Add a notification to the page
             *
             * @param notification
             */
            add(notification) {
                this.notifications.push(notification);
            },

            /**
             * Dismiss a notification
             *
             * @param index
             */
            dismiss(index) {
                this.notifications.splice(index, 1);
            },

            /**
             * Get the html of an action entry
             *
             * @param action
             * @return {string}
             */
            getEntryAction(action) {
                switch (action.type) {
                    case 'link': return `
                        <a class="px-3" href='${action.url}' wire:navigate='${action.navigate}'>
                           ${action.text}
                        </a>
                    `;
                }
            },

            /**
             * Get the html of a content entry
             *
             * @param content
             * @return {string}
             */
            getEntryContent(content) {
                const contentKey = Object.keys(content)[0];
                const contentData = content[contentKey];

                switch (contentKey) {
                    case 'line': return `
                        ${contentData}
                    `;
                }
            },

            /**
             * Mark notification as read
             *
             * @param index
             * @param notificationId
             */
            markAsRead(index, notificationId)
            {
                this.dismiss(index);

                Livewire.dispatch('notification.mark-as-read', {notification: notificationId});
            }
        };
    });
});