import "./multiselect/item";
import "./multiselect/selected-item-template";

Alpine.data("multiselect", (modelClass) => {
  let _this;
  return {
    inputId: null,
    searchInput: "",
    selectedWireModel: null,
    searchWireModel: null,
    selectedItems: {},
    element: null,
    hasSearchModel: false,
    emptySelection: false,
    init() {
      _this = this;

      // Check if model-class is set
      if (modelClass === "" || typeof modelClass === "undefined") {
        cmch.throw("EJS1002");
      }
    },
    initSearchInput() {
      let attributes = this.$el.attributes;

      // Iterate over the attributes
      for (let i = 0; i < attributes.length; i++) {
        let attr = attributes[i];

        // Check if attribute starts with wire:model
        if (attr.name.startsWith("wire:search")) {
          this.searchWireModel = attr.value;

          break;
        }
      }

      if (!this.searchWireModel) {
        cmch.throw("EJS1000");

        return;
      }

      if (this.searchWireModel) {
        this.searchInput = this.$wire.$get(this.searchWireModel);

        this.$wire.$watch(this.searchWireModel, (updatedSearch) => {
          this.searchInput = updatedSearch;
        });
      }

      this.$watch(
        "searchInput",
        (updatedValue) => {
          if (this.searchWireModel === "") {
            return;
          }

          _this.$wire.$set(this.searchWireModel, updatedValue);
        },
        300,
      );
    },
    initSelectedInput() {
      let inputValue = this.$el.value;

      this.$wire
        .call("registerMultiselectInput", modelClass)
        .then((response) => {
          this.inputId = response;

          if (typeof this.inputId === "undefined") {
            cmch.throw("EJS1003");

            return;
          }

          let attributes = this.$el.attributes;

          // Iterate over the attributes
          for (let i = 0; i < attributes.length; i++) {
            let attr = attributes[i];

            // Check if attribute starts with wire:model
            if (attr.name.startsWith("wire:model")) {
              this.selectedWireModel = attr.value;

              break;
            }
          }

          if (!this.selectedWireModel) {
            cmch.throw("EJS1004");

            return;
          }

          this.$wire.$watch(this.selectedWireModel, (newValue, oldValues) => {
            if (newValue.length < oldValues.length) {
              let removedItems = oldValues.filter(
                (item) => !newValue.includes(item),
              );

              this.removeItems(removedItems);

              return;
            }
            this.addItems(newValue);
          });

          this.addItems(inputValue);
        });
    },
    removeItems(modelIds) {
      this.$wire.call(
        "removeMultiselectItems",
        _this.inputId,
        modelIds,
      ).then((result) => {
        this.selectedItems = result;
      });
    },
    addItems(modelIds) {
      if (modelIds === "") {
        return;
      }

      if (typeof modelIds === "string") {
        modelIds = modelIds.split(",");
      }

      if (typeof modelIdsString === "number") {
        modelIds = [modelIds];
      }

      this.$wire.call(
        "appendMultiselectItems",
        _this.inputId,
        modelIds,
      ).then((result) => {
        this.selectedItems = result;
      });
    },
  };
});
