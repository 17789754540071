Alpine.data("tooltip", () => {
  return {
    isVisible: false,
    clickOpened: false,
    cursorPosition: { x: 0, y: 0 },
    init() {
      this.registerEventListeners();
    },
    registerEventListeners() {
      this.$refs.icon?.addEventListener("mouseenter", () => this.show());
      this.$refs.icon?.addEventListener("mousemove", (e) => this.move(e));
      this.$refs.icon?.addEventListener("mouseleave", () => this.hide());
      this.$refs.icon?.addEventListener("click", (e) => this.click(e));
    },
    show() {
      this.setTooltipPosition();

      this.isVisible = true;
    },
    hide() {
      this.isVisible = false;
    },
    click(e) {
      setTimeout(() => {
        this.move(e);
      }, 100);
    },
    move(e) {
      this.cursorPosition.x = e.clientX;

      this.cursorPosition.y = e.clientY;

      this.setTooltipPosition();
    },
    setTooltipPosition() {
      let iconRect = this.getIconRect();
      let containerRect = this.getContainerRect();
      let windowSize = this.getWindowSize();

      let smallScreenWidthThreshold = windowSize.width - containerRect.width;
      let offset = 0;

      if (smallScreenWidthThreshold < containerRect.width) {
        offset = containerRect.width - smallScreenWidthThreshold;

        if (offset > smallScreenWidthThreshold) {
          offset = iconRect.left;
        }
      }

      let cursorOffset = {
        x:
          windowSize.width -
          iconRect.left -
          (windowSize.width - this.cursorPosition.x),
        y:
          windowSize.height -
          iconRect.top -
          (windowSize.height - this.cursorPosition.y),
      };

      let left = 0;
      let top = iconRect.height * 0.5 + iconRect.top;

      if (windowSize.width > 640) {
        left = iconRect.width * 0.5 - offset + cursorOffset.x;
        top = iconRect.height * 0.5 + cursorOffset.y;
      }

      this.$refs.container.style.top = `${top}px`;
      this.$refs.container.style.left = `${left}px`;
    },
    getWindowSize() {
      return { width: window.innerWidth, height: window.innerHeight };
    },
    getContainerRect() {
      return this.$refs.container.getBoundingClientRect();
    },
    getIconRect() {
      return this.$refs.icon.getBoundingClientRect();
    },
  };
});
