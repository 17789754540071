/* global Livewire */
document.addEventListener("livewire:init", () => {
  window.pageExceptions = () => {
    return {
      exceptions: [],
      init() {
        Livewire.hook("request", ({ fail }) => {
          fail(({ status, content, preventDefault }) => {
            if (!content.includes("</html>")) {
              this.addException(this, status, content, preventDefault);
              preventDefault();
            }
          });
        });
      },
      addException: (_this, status, content) => {
        _this.exceptions[_this.exceptions.length] = {
          status: status,
          content: content,
        };
      },
      understood(index) {
        this.exceptions.splice(index, 1);
      },
    };
  };
});
