Alpine.data("unorderedListItem", () => {
  return {
    durationBase: 20,
    display: false,
    init() {
      // Display immediately when an index is not available
      if (typeof this.index === "undefined") {
        this.show();

        return;
      }

      // Display delay determined by the specified index,
      // applied after the given timeout.
      setTimeout(() => {
        this.show();
        this.reset();
      }, this.index * this.durationBase);

      this.index = this.index + 1;
    },
    show() {
      this.display = true;
    },
    reset() {
      if (this.index !== 0) {
        this.index = 0;
      }
    },
  };
});
