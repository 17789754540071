import "./navigation/item";

Alpine.data("navigation", () => {
  let _this;
  return {
    activeNavigationItem: null,
    mobileActionsOpen: false,
    mobileActionsReferences: [],
    pageHasMobileActions: false,
    mobileActionInitialized: false,
    navigationItems: [],
    navigationItemsCount: 0,
    navigationScrollPosition: 0,
    lastScrollTop: 0,
    init() {
      _this = this;

      document.addEventListener("livewire:navigated", (event) => {
        this.loadPageMobileActions();
      });

      document.getElementById("main").addEventListener(
        "scroll",
        function () {
          _this.scrollBehavior(this.scrollTop, (this.scrollHeight / 2) * 0.25);
        },
        false,
      );
    },
    scrollBehavior(scrollTop, stayHiddenScrollHeight) {
      const offsetTrigger = 100;
      const positionStepAmount = 100;

      if (window.innerWidth > breakpoints.sm) {
        this.$el.style.transform = `translateY(0px)`;

        return;
      }

      if (scrollTop > this.lastScrollTop) {
        if (this.navigationScrollPosition < offsetTrigger) {
          this.navigationScrollPosition =
            this.navigationScrollPosition + positionStepAmount;
        }
      } else {
        if (this.navigationScrollPosition > 0) {
          if (stayHiddenScrollHeight > scrollTop) {
            this.navigationScrollPosition =
              this.navigationScrollPosition - positionStepAmount;
          }
        }
      }

      this.$el.style.transform = `translateY(${this.navigationScrollPosition}px)`;

      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For mobile or negative scrolling
    },
    loadPageMobileActions() {
      this.mobileActionsReferences =
        document.querySelectorAll("[mobile-action]");

      this.mobileActionsReferences.forEach((reference) => {
        reference.setAttribute("wire:ignore", true);
      });

      this.pageHasMobileActions = this.mobileActionsReferences.length > 0;

      this.handleMobileActionOnResize();
    },
    windowResized() {
      this.navigationRepositionItemMarker(this.activeNavigationItem);
      this.handleMobileActionOnResize();
    },
    handleMobileActionOnResize() {
      if (!this.pageHasMobileActions) {
        return;
      }

      if (window.innerWidth < breakpoints.sm) {
        if (!this.mobileActionInitialized) {
          this.mobileActionsReferences.forEach((reference) => {
            reference.style.display = "none";
            reference.style.position = "absolute";
            reference.style.scale = "0px";
          });

          this.mobileActionInitialized = true;
        }

        return;
      }

      if (this.mobileActionInitialized) {
        this.mobileActionsReferences.forEach((reference) => {
          reference.style.display = "";
          reference.style.position = "";
          reference.style.scale = "";
        });

        this.mobileActionInitialized = false;
      }
    },
    navigationItemClicked(navigationItem) {
      this.navigationRepositionItemMarker(navigationItem);
    },
    navigationRepositionItemMarker(navigationItem) {
      this.activeNavigationItem = navigationItem;

      if (window.innerWidth > breakpoints.xl) {
        //this.$refs.navigationItemMarker.style.width = null;
        this.$refs.navigationItemMarker.style.height =
          navigationItem.offsetHeight + "px";
        this.$refs.navigationItemMarker.style.left = "5px";
        this.$refs.navigationItemMarker.style.top =
          navigationItem.offsetTop + "px";

        return;
      }

      //this.$refs.navigationItemMarker.style.width = "100%";
      this.$refs.navigationItemMarker.style.left =
        navigationItem.offsetLeft + "px";
      this.$refs.navigationItemMarker.style.top =
          navigationItem.offsetTop + "px";
    },
    mobileActionClose() {
      this.mobileActionsOpen = false;
    },
    mobileActionShow() {
      if (!this.pageHasMobileActions) {
        this.mobileActionClose();

        return;
      }

      this.mobileActionsOpen = true;
    },
  };
});
