let isProduction = import.meta.env.PROD;

let logger = class {
  #namePrefix = "AlutionUi";

  #typeTags = {
    error: "Error",
    warning: "Warning",
    info: "Info",
    success: "Success",
  };

  #typeColors = {
    error: "color: red; font-weight: bolder;",
    warning: "color: yellow; font-weight: bold;",
    info: "color: #33b1ec; font-weight: bold;",
    default: "color: white;",
    success: "color: lightgreen; font-weight: bold;",
    alution: `color: #222222; background-color: #33b1ec; font-weight: normal;`,
    syntax: "color: mediumpurple; font-weight: bolder;",
    boolean: "color: green;",
    number: "color: orange;",
  };

  constructor() {
    const year = new Date().getFullYear();

    const initialMessage = `AlutionUI © ${year} Alution Software GmbH`;

    this.info(initialMessage, initialMessage);
  }

  default(prodCodeMessage, ...content) {
    if (isProduction) {
      console.log(prodCodeMessage);

      return;
    }

    console.log(...content);
  }

  info(prodCodeMessage, ...content) {
    this.#logMessageStack("info", prodCodeMessage, ...content);
  }

  success(prodCodeMessage, ...content) {
    this.#logMessageStack("success", prodCodeMessage, ...content);
  }

  warning(prodCodeMessage, ...content) {
    this.#logMessageStack("warning", prodCodeMessage, ...content);
  }

  error(prodCodeMessage, ...content) {
    this.#logMessageStack("error", prodCodeMessage, ...content);
  }

  #logMessageStack(type, prodCodeMessage, ...content) {
    // Track log outputs called on the same request
    let logId = content.length > 1 ? Math.floor(Math.random() * 1000) : "";

    let parameterBag = [];

    if (this.#hasParameterBag(content)) {
      parameterBag = content.pop();
    }

    content.forEach((value, index) =>
      this.#logSingleMessage(
        type,
        logId,
        prodCodeMessage,
        value,
        ...(parameterBag[index] ?? []),
      ),
    );
  }

  #logSingleMessage(type, logId, prodCodeMessage, content, ...parameter) {
    if (parameter.length === 0) {
      parameter[0] = this.#typeColors.default;
    }

    let _content = content;

    if (isProduction) {
      if (
        prodCodeMessage === null ||
        prodCodeMessage === undefined ||
        prodCodeMessage === ""
      ) {
        return;
      }

      _content = prodCodeMessage;
    }

    let contentType = typeof _content;

    if (_content === undefined) {
      contentType = "undefined";
      _content = undefined;
    }

    if (_content?.length === 0) {
      _content = { null: null };
    }

    switch (contentType) {
      case "string":
        this.#logByType(type, ...this.#getBase(type, logId, _content));
        break;
      case "object" || "array":
        console.log(
          ...this.#getBase(
            type,
            logId,
            `(%c${this.#toCamelCase(contentType)}%c) [`,
          ),
          this.#typeColors.syntax,
          this.#typeColors.default,
        );
        for (let [key, value] of Object.entries(_content)) {
          console.log(
            `    {\n        %c${key}:`,
            this.#typeColors.syntax,
            value,
            `\n    },`,
          );
        }
        console.log("];");
        break;
      default:
        this.#logByType(
          type,
          ...this.#getBase(
            type,
            logId,
            `(%c${this.#toCamelCase(contentType)}%c) %c${_content}%c`,
          ),
          this.#typeColors.syntax,
          this.#typeColors.default,
          this.#typeColors[contentType],
          ...parameter,
        );
    }
  }

  #logByType(type, ...content) {
    if (isProduction) {
      console.log(...content);

      return;
    }

    switch (type) {
      case "info":
        console.info(...content);
        break;
      case "warning":
        console.warn(...content);
        break;
      case "error":
        console.error(...content);
        break;
      default:
        console.log(...content);
    }
  }

  #getBase(type, logId, content) {
    let typeTagString = "%c";
    let typeTagColor = this.#typeColors.default;

    if (this.#typeTags.hasOwnProperty(type)) {
      typeTagString = this.#typeTags[type];
    }

    if (this.#typeColors.hasOwnProperty(type)) {
      typeTagColor = this.#typeColors[type];
    }

    // Set Arrow for better readability
    if (logId !== "") {
      logId = ` ➤ ${logId}`;
    }

    return [
      `%c ${this.#namePrefix} %c ${typeTagString}${logId} ➤ %c${content}`,
      this.#typeColors.alution,
      typeTagColor,
      this.#typeColors.default,
    ];
  }

  #hasParameterBag(contentArray) {
    let lastIndex = contentArray.length - 1;

    let content = contentArray[lastIndex];

    if (!(content instanceof Object)) {
      return false;
    }

    if (content.length === 0) {
      return false;
    }

    return content.hasOwnProperty("parameter");
  }

  #toCamelCase(str) {
    return str.replace(/^\w|[A-Z]|\b\w|\s+/g, function (match, index) {
      if (Number(match) === 0) {
        return ""; // or if (/\s+/.test(match)) for white spaces
      }

      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }
};

window.logger = new logger();
