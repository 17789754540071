// noinspection JSUnresolvedReference
/*global Livewire*/

/**
 * Initializes a dashboard with the given widgets.
 *
 * @param {Array} widgets - An array of widget objects to be displayed on the dashboard.
 * @return {Object} - An object representing the dashboard.
 */
Alpine.data('dashboard', (widgets) => {
  let _this;
  return {
    widgets: widgets,
    gridStack: null,
    renderedWidgetContent: {},

    init() {
      _this = this;
    },

    setup() {
      this.gridStack = this.initGridStack();
    },

    initGridStack() {
      let widgets = this.mapWidgets(this.widgets);

      let gridStack = GridStack.init({
        animate: true,
        float: true,
        minColumn: 12,
        minRow: 3,
        maxColumn: 5,
        handle: ".widget-header",
        acceptWidgets: function () {
          return true;
        },
        children: widgets,
      });

      GridStack.setupDragIn(".slide-over-widgets .grid-stack-item", {
        appendTo: "grid-stack",
        helper: this.gridStackClone,
        start: this.gridStackStart,
      });

      gridStack.on(
          "added removed change dragstart dragstop dropped",
          this.onGridStackEvent,
      );

      // This ensures Livewire register all of our manually added widgets
      this.gridStackItemsUpdate(widgets);

      return gridStack;
    },

    contextmenu(event) {
      event.preventDefault();

      this.setWidgetSlideOverOpenState(true);
    },

    gridStackClone() {
      const template = document.getElementById("widget-template");

      return template.cloneNode(true);
    },

    gridStackStart() {
      _this.setWidgetSlideOverOpenState(false);
    },

    setWidgetSlideOverOpenState(open) {
      Livewire.dispatch("slide-over.available-widgets.open", { isOpen: open });
    },

    removeWidget(id) {
      this.gridStack.removeWidget(document.querySelector(`[gs-id="${id}"]`));
    },

    mapWidgets(widgets) {
      return widgets.map((widget) => {
        return this.mapSingleeWidget(widget);
      });
    },

    mapSingleeWidget(widget) {
      return {
        id: widget.id,
        content: widget.content,
        x: widget.left,
        y: widget.top,
        w: widget.width,
        h: widget.height,
      };
    },

    gridStackItemDelete(item) {
      let widget = item[0];

      if (widget.id) {
        Livewire.dispatch("widget.delete", {
          widget: item[0].id,
        });
      }
    },

    gridStackSingleItemUpdate(item) {
      Livewire.dispatch("widget.update", {
        widget: item.id,
        data: {
          left: item.x,
          top: item.y,
          width: item.w,
          height: item.h,
        },
      });
    },

    gridStackItemsUpdate(items) {
      items.forEach(function (item) {
        _this.gridStackSingleItemUpdate(item);
      });
    },

    gridStackItemCreate(item) {
      item = item[0];

      let identifier = (Math.random() + 1).toString(36).substring(7);

      Livewire.dispatch("widget.create", {
        identifier: identifier,
        data: {
          widget_class: item.el.getAttribute("widget-class"),
          left: item.x,
          top: item.y,
          width: item.w,
          height: item.h,
        },
      });

      Livewire.on(`widget.${identifier}`, function (data) {
        _this.gridStack.removeWidget(item.el);
        item = _this.mapSingleeWidget(data.widget);
        _this.gridStack.addWidget(item);
      });
    },

    onGridStackEvent(event, ...parameter) {
      switch (event.type) {
        case "change":
          _this.gridStackItemsUpdate(...parameter);
          break;
        case "added":
          _this.gridStackItemCreate(...parameter);
          break;
        case "removed":
          _this.gridStackItemDelete(...parameter);
          break;
        case "dropped":
          console.log(`[Alution-Dashboard] Dropped:`, ...parameter);
          break;
        default:
          console.log(`[Alution-Dashboard] Event ${event.type} is unhandled!`);
      }
    },
  };
});