import "./bootstrap";
import "./alution-ui/alution-ui";
import "./echo.js";
// import "./app/dashboard";
import "./app/slide-over";
import "./app/confirmation";
import "./app/notifications";
import "./app/page-title-handler";
import "./app/components/livewire/dashboard";
import "./app/time-stamp-slider";
import "./app/table-builder";
import "./app/page-exceptions";
import "./app/page-notifications";

import 'livewire-sortable';

/*

import {createSwapy} from "swapy";

const container = document.querySelector('.container')

window.swapy = createSwapy(container, {
    animation: 'dynamic', // or spring or none,
    continuousMode:true
})

// You can disable and enable it anytime you want
swapy.enable(true);

swapy.onSwap(function(i) {
    console.log(i);
})
*/

import stopwatch from "./app/stopwatch";
import audioPlayer from "./app/audio-player";
import videoPlayer from "./app/video-player";

Alpine.data("stopwatch", stopwatch);
Alpine.data("audioPlayer", audioPlayer);
Alpine.data("videoPlayer", videoPlayer);