Alpine.data("navigationItem", (layoutBackground) => {
  return {
    initialized: false,
    init() {
      this.setLayoutBackground();
    },
    windowResized() {
      this.setLayoutBackground();
    },
    setLayoutBackground() {
      this.$refs.container.classList.remove(...layoutColors.getTextColorAsArray(layoutBackground));
      this.$refs.icon.classList.add(...layoutColors.getAsArray(layoutBackground));
    },
    isActive() {
      return (this.getFirstUrlPartFromString(this.$el.href) === this.getFirstUrlPartFromString(location.href));
    },
    getFirstUrlPartFromString(urlString) {
      return new URL(urlString).pathname.split("/")[1] ?? "";
    },
  };
});
