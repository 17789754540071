let consoleMessageCodeHandler = class {
  // All Codes
  #code = {
    // ERROR ALUTION BACKEND
    EAB1000:
      "The value of $_SlideOverId could not be loaded from the server. Possibly, the 'HasAlutionUiSlideOver' trait is missing in the Livewire class. If it should be set, the problem could be that the getSlideOverId() method was overridden, but returns an empty string as a response.",

    // WARNING USER INPUT
    WUI1000:
      "The user is attempting to close a dialog that is currently waiting for a response to a previous server request.",
    WUI1001:
      "Attempting to open a dialog that is currently waiting for a response to a previous server request.",
    WUI1002:
      "Attempting to select an option in a dialog that is currently waiting for a response to a previous server request.",

    // ERROR JAVASCRIPT
    EJS1000:
      "Attempting to search on multiselect field which is missing the 'wire:search' attribute!",
    EJS1001:
      "Attempted to load Date-Picker translation, but neither the target nor the fallback value exists!",
    EJS1002:
      "The Multiselect has no 'model-class' defined, therefore it cannot be initialized!",

    // WARNING JAVASCRIPT
    WJS1000:
      "The requested slide-over state change didn't receive a timely response, either due to a slow internet connection or related to a previously logged error. Therefore, the original slide-over state was restored.",
  };

  throw(code, ...data) {
    let type = this.#getExceptionType(code);

    let content = this.#getContent(code);

    this.#logException(type, code, content, data);
  }

  #logException(type, code, content, data) {
    if (content === null) {
      content = code;

      code = null;
    }

    logger[type](code, content, ...data);
  }

  #getContent(code) {
    let content = this.#code[code];

    if (typeof content === "undefined") {
      return null;
    }

    return content;
  }

  #getExceptionType(code) {
    switch (code[0].toLowerCase()) {
      case "w":
        return "warning";
      case "e":
        return "error";
      case "i":
        return "info";
      case "s":
        return "success";
      default:
        return "default";
    }
  }
};

window.cmch = new consoleMessageCodeHandler();
