import { getContrastYIQ } from "./helpers/colors.js";

Alpine.data("badge", (hex) => {
  const textColor = getContrastYIQ(hex);

  return {
    init() {
      this.$el.style.backgroundColor = hex;
      this.$el.style.borderColor = hex;
      this.$el.style.color = textColor;
    },
  };
});