/**
 * The rose is red, the violet's blue, this JavaScript code is shit, and so is CS2!
 *
 * @type {{de: {months: string[], months_short: string[], days: string[], days_short: string[]}, en: {months: string[], months_short: string[], days: string[], days_short: string[]}}}
 */

const translations = {
  en: {
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    months_short: [
      "Jan.",
      "Feb.",
      "Mar.",
      "Apr.",
      "May.",
      "Jun.",
      "Jul.",
      "Aug.",
      "Sept.",
      "Oct.",
      "Nov.",
      "Dec.",
    ],
    days: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    days_short: ["Sun.", "Mon.", "Tue.", "Wed.", "Thu.", "Fri.", "Sat."],
  },
  de: {
    months: [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ],
    months_short: [
      "Jan.",
      "Feb.",
      "Mrz.",
      "Apr.",
      "Mai.",
      "Jun.",
      "Jul.",
      "Aug.",
      "Sep.",
      "Okt.",
      "Nov.",
      "Dez.",
    ],
    days: [
      "Sonntag",
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag",
    ],
    days_short: ["So.", "Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa."],
  },
};

const fallbackLocale = "en";

Alpine.data(
  "datePicker",
  (_datePickerFormat, _wireModelStart, _wireModelEnd, _timezone) => {
    return {
      timezone: _timezone,
      datePickerOpen: false,
      datePickerValue: "",
      datePickerFormat: _datePickerFormat,
      datePickerMonth: 0,
      datePickerYear: 0,
      datePickerYearShort: 0,
      datePickerDay: 0,
      datePickerDaysInMonth: [],
      overflowDaysLastMonth: [],
      overflowDaysNextMonth: 0,
      datePickerMonthNames: null,
      datePickerDays: null,
      datePickerMonthShortNames: null,
      datePickerDaysShort: null,
      subitemsRegistered: false,
      timespanText: "00:00",
      firstOpenAt: null,
      datePickerTimeStartValue: null,
      datePickerTimeEndValue: null,
      timeSelectionStartText: "",
      timeSelectionEndText: "",
      preciseMode: false,
      pixelStart: null,
      timeEnd: null,
      currentTimeSelection: null,
      wireModelStart: _wireModelStart,
      wireModelEnd: _wireModelEnd,
      startDate: null,
      endDate: null,
      hasTimeSelection: false,
      init() {
        this.loadTranslations();

        this.$watch("datePickerOpen", (isOpen) => {
          if (!this.firstOpenAt && isOpen) {
            setTimeout(() => {
              if (this.wireModelStart) {
                this.currentTimeSelection = {
                  hour: this.startDate?.getHours() ?? 0,
                  minutes: this.startDate?.getMinutes() ?? 0,
                };

                this.setTimeStart();
              }

              if (this.wireModelEnd) {
                this.currentTimeSelection = {
                  hour: this.endDate?.getHours() ?? 0,
                  minutes: this.endDate?.getMinutes() ?? 0,
                };

                this.setTimeEnd();
              }

              this.loadCurrentTimelineTime();

              this.firstOpenAt = Date.now();
            }, 100);
          }
        });

        this.$wire.$watch(this.wireModelStart, (value) => {
          this.startDate = value ? new Date(Date.parse(value)) : null;
          this.updateDateInput(true);
          this.datePickerCalculateDays();
        });

        this.$wire.$watch(this.wireModelEnd, (value) => {
          this.endDate = value ? new Date(Date.parse(value)) : null;
          this.updateDateInput(true);
          this.datePickerCalculateDays();
        });

        this.$refs.timeline.addEventListener("mouseenter", (e) => this.updateTimespan(e));
        this.$refs.timeline.addEventListener("mousemove", (e) => this.updateTimespan(e));
        this.$refs.timeline.addEventListener("click", (e) => this.setTime(e));

        this.startDate = this.getModelDate(this.wireModelStart) ?? null;
        this.endDate = this.getModelDate(this.wireModelEnd) ?? null;

        this.setDefaults();

        if (this.startDate) {
          this.datePickerDayClicked(this.startDate.getDate());
        }

        this.updateDateInput();

        this.datePickerCalculateDays();
      },
      getModelDate(model) {
        if (!model) {
          return null;
        }

        let modelDate = this.$wire.$get(model);

        if (modelDate) {
          let value = new Date(Date.parse(modelDate));

          if (value) {
             value.setTime(value.getTime() + value.getTimezoneOffset() * 60 * 1000 );
          }

          return value;
        }

        return null;
      },
      setModelDate(model, value) {
        let _value = null;

        if (value) {
          _value = new Date(value);
          _value.getVarDate

         // console.log(_value.getTime(), value.getTime())

          // fix offset
          _value.setTime(_value.getTime() - _value.getTimezoneOffset() * 60 * 1000);
        }

        return this.$wire.$set(model, _value);
      },
      hasDateHoursMinutes() {
        return this.datePickerFormat.includes("H:i");
      },
      updateDateInput(withoutUpdatingModel = false) {
        this.startDate?.setSeconds(0);
        this.endDate?.setSeconds(0);

        let _endDate = null;

        if (Date.parse(this.startDate) !== Date.parse(this.endDate)) {
          _endDate = this.endDate;
        }

        if (this.wireModelStart && !withoutUpdatingModel) {
          this.setModelDate(this.wireModelStart, this.startDate);
        }

        if (this.wireModelEnd && !withoutUpdatingModel) {
          this.setModelDate(this.wireModelEnd, _endDate);
        }

        this.setDatePickerFormatDate(this.startDate, _endDate);
      },
      closeDatePicker() {
        if (this.datePickerOpen) {
          this.$refs.datePickerInput.blur();

          if (this.startDate && !this.datePickerDay) {
            this.datePickerDay = this.startDate.getDate();
          }

          this.datePickerOpen = false;

          this.updateDateInput();
        }
      },
      openDatePicker() {
        this.datePickerOpen = true;

        this.$refs.datePickerInput.focus();
      },
      startPreciseMode() {
        this.preciseMode = true;
      },
      stopPreciseMode() {
        this.preciseMode = false;
      },
      setDefaults() {
        let date = new Date();

        this.datePickerMonth = date.getMonth();
        this.datePickerYear = date.getFullYear();
        this.datePickerYearShort = String(this.datePickerYear).slice(2, 4);
        this.datePickerDay = date.getDate();
      },
      reset(resetWithoutModel = false) {
        this.pixelStart = this.pixelEnd = 0;
        this.timeSelectionStartText = this.timeSelectionEndText = "";
        this.datePickerValue = "";
        this.datePickerTimeStartValue = null;
        this.datePickerTimeEndValue = null;
        this.timeSelectionStartText = "";
        this.timeSelectionEndText = "";
        this.pixelStart = null;
        this.timeEnd = null;
        this.currentTimeSelection = null;

        this.endDate = null;
        this.startDate = null;

        this.hasTimeSelection = false;

        if (this.wireModelStart && !resetWithoutModel) {
          this.setModelDate(this.wireModelStart, null);
        }

        if (this.wireModelEnd && !resetWithoutModel) {
          this.setModelDate(this.wireModelEnd, null);
        }

        this.setDefaults();
        this.setTimeSelectionHeight(0);
      },
      setTime(e) {
        this.hasTimeSelection = true;

        if (!this.currentTimeSelection) {
          this.updateTimespan(e);
        }

        if (this.pixelStart && this.pixelEnd) {
          this.pixelStart = this.pixelEnd = 0;
          this.timeSelectionStartText = this.timeSelectionEndText = "";

          this.setTimeSelectionHeight(30);
        }

        if (!this.pixelStart) {
          this.setTimeStart();

          return;
        }

        if (!this.pixelEnd) {
          this.setTimeEnd();
        }
      },
      setTimeStart() {
        if (!this.startDate) {
          this.startDate = new Date();
        }

        this.startDate.setHours(this.currentTimeSelection.hour);
        this.startDate.setMinutes(this.currentTimeSelection.minutes);

        this.setTimeSelectionStartText(this.startDate);

        this.pixelStart = this.getPixelFromHourAndMinutes(this.startDate);

        this.$refs.timeSelection.style.top = this.pixelStart + "px";
      },
      setTimeEnd() {
        if (!this.endDate) {
          this.endDate = new Date();
          this.endDate.setMonth(this.startDate.getMonth());
          this.endDate.setDate(this.datePickerDay);
        }

        this.endDate.setHours(this.currentTimeSelection.hour);
        this.endDate.setMinutes(this.currentTimeSelection.minutes);

        this.setTimeSelectionEndText(this.endDate);

        let tempPixelEnd = this.getPixelFromHourAndMinutes(this.endDate);

        if (tempPixelEnd > this.pixelStart) {
          this.pixelEnd = tempPixelEnd;

          return;
        }

        this.setTimeStart();
      },
      getTimeSelectionRect() {
        return this.$refs.timeSelection.getBoundingClientRect();
      },
      getTimelineRect() {
        return this.$refs.timeline.getBoundingClientRect();
      },
      getTimelineContainerRect() {
        return this.$refs.timelineContainer.getBoundingClientRect();
      },
      setTimespanPosition(pixel) {
        this.$refs.timespan.style.top = `${pixel}px`;
      },
      setTimespanCursorLeft(left) {
        this.$refs.timespanCursor.style.left = `${left}px`;
      },
      setTimeSelectionHeight(pixel) {
        this.$refs.timeSelection.style.height = `${pixel}px`;
      },
      getCursorInnerRectPosition(e, rect) {
        let cursorY = e.clientY - rect.y;
        let cursorX = e.clientX - rect.x;

        if (cursorY < 0) {
          cursorY = 0;
        }

        if (cursorX < 0) {
          cursorX = 0;
        }

        return {
          x: cursorX,
          y: cursorY,
        };
      },
      getPixelTimePosition(cursorInnerRectPosition, timelineRect) {
        return cursorInnerRectPosition / (timelineRect.height / 24);
      },
      updateTimespan(e) {
        let timelineRect = this.getTimelineRect();

        let cursor = this.getCursorInnerRectPosition(e, timelineRect);

        this.setTimespanPosition(cursor.y);

        this.setTimespanCursorLeft(cursor.x);

        let pixelPosition = this.getPixelTimePosition(cursor.y, timelineRect);

        if (this.pixelStart && !this.pixelEnd) {
          let height = cursor.y - this.pixelStart;

          if (height) {
            this.setTimeSelectionHeight(height);
          }
        }

        this.setTimespanText(this.getTimeFromPixelPosition(pixelPosition));
      },
      getPixelFromHourAndMinutes(date) {
        return (
          (date.getHours() + date.getMinutes() / 60) *
          (this.getTimelineRect().height / 24)
        );
      },
      setTimeSelectionStartText(time) {
        this.timeSelectionStartText = this.timeToString(time);
      },
      setTimeSelectionEndText(time) {
        this.timeSelectionEndText = this.timeToString(time);
      },
      setTimespanText(time) {
        this.timespanText = this.timeToString(time);
      },
      timeToString(time) {
        if (time instanceof Date) {
          let temp = time;

          time = {
            hour: temp.getHours(),
            minutes: temp.getMinutes(),
          };
        }

        let timeHour = time.hour < 10 ? `0${time.hour}` : time.hour;

        let timeMinutes = time.minutes < 10 ? `0${time.minutes}` : time.minutes;

        return `${timeHour}:${timeMinutes}`;
      },
      cleanTimeValues(hour, minutes) {
        if (this.preciseMode) {
          return {
            hour: hour,
            minutes: minutes,
          };
        }

        if (minutes < 10) {
          minutes = 0;
        }

        if (minutes > 10 && minutes < 20) {
          minutes = 15;
        }

        if (minutes > 20 && minutes < 40) {
          minutes = 30;
        }

        if (minutes > 40 && minutes < 50) {
          minutes = 45;
        }

        if (minutes > 50) {
          minutes = 0;
          hour++;
        }

        return {
          hour: hour,
          minutes: minutes,
        };
      },
      getTimeFromPixelPosition(pixel) {
        // Umrechnung einer Pixelposition in eine Uhrzeit
        let hour = Math.floor(pixel);

        let minutes = Math.round((pixel - hour) * 60);

        return (this.currentTimeSelection = this.cleanTimeValues(
          hour,
          minutes,
        ));
      },
      loadCurrentTimelineTime() {
        let now =
          this.startDate.getHours() || this.startDate.getMinutes()
            ? this.startDate
            : new Date();

        this.setTimespanText(now);

        let pixelPosition = this.getPixelFromHourAndMinutes(now);

        this.setTimespanPosition(pixelPosition);

        let timelineContainerRect = this.getTimelineContainerRect();

        this.$refs.timelineContainer.scrollTo(
          0,
          pixelPosition - timelineContainerRect.height * 0.5,
        );
      },
      loadTranslations() {
        // english will be the fallback value if lang is empty
        const activeLanguage =
          document.documentElement.getAttribute("lang") ?? fallbackLocale;

        if (translations.hasOwnProperty(activeLanguage)) {
          this.datePickerDays = translations[activeLanguage].hasOwnProperty(
            "days",
          )
            ? translations[activeLanguage].days
            : translations[fallbackLocale].days;

          this.datePickerDaysShort = translations[
            activeLanguage
          ].hasOwnProperty("days_short")
            ? translations[activeLanguage].days_short
            : translations[fallbackLocale].days_short;

          this.datePickerMonthNames = translations[
            activeLanguage
          ].hasOwnProperty("months")
            ? translations[activeLanguage].months
            : translations[fallbackLocale].months;

          this.datePickerMonthShortNames = translations[
            activeLanguage
          ].hasOwnProperty("months_short")
            ? translations[activeLanguage].months_short
            : translations[fallbackLocale].months_short;

          logger.info(
            null,
            `Loaded translations for Date-Picker element in "${activeLanguage}"`,
          );

          return;
        }

        cmch.throw("EJS1001");
      },
      datePickerDayClicked(day) {
        this.datePickerDay = day;

        if (!this.startDate) {
          this.startDate = new Date();
        }

        this.startDate.setDate(day);
        this.startDate.setMonth(this.datePickerMonth);

        if (this.endDate) {
          this.endDate.setDate(day);
          this.endDate.setMonth(this.datePickerMonth);
        }

        this.datePickerIsSelectedDate(day);
      },
      datePickerPreviousMonth() {
        if (this.datePickerMonth === 0) {
          this.datePickerYear--;

          this.datePickerMonth = 12;
        }

        this.datePickerMonth--;

        this.datePickerCalculateDays();
      },
      datePickerNextMonth() {
        if (this.datePickerMonth === 11) {
          this.datePickerMonth = 0;

          this.datePickerYear++;

          this.datePickerCalculateDays();

          return;
        }

        this.datePickerMonth++;

        this.datePickerCalculateDays();
      },
      datePickerIsSelectedDate(day) {
        if (this.datePickerDay !== day) {
          return false;
        }

        if (this.datePickerMonth !== this.startDate?.getMonth()) {
          return false;
        }

        return this.datePickerYear === this.startDate?.getFullYear();
      },
      datePickerIsToday(day) {
        const today = new Date();

        const d = new Date(this.datePickerYear, this.datePickerMonth, day);

        return today.toDateString() === d.toDateString();
      },
      datePickerCalculateDays() {
        let daysInMonth = new Date(
          this.datePickerYear,
          this.datePickerMonth + 1,
          0,
        ).getDate();
        let daysPreviousMonth = new Date(
          this.datePickerYear,
          this.datePickerMonth,
          0,
        ).getDate();

        // find where to start calendar day of the week
        let dayOfWeek = new Date(
          this.datePickerYear,
          this.datePickerMonth,
        ).getDay();

        let overflowDaysLastMonth = [];
        for (let i = 1; i <= dayOfWeek; i++) {
          overflowDaysLastMonth.unshift(daysPreviousMonth--);
        }

        let daysArray = [];
        for (let i = 1; i <= daysInMonth; i++) {
          daysArray.push(i);
        }

        let currentCols = daysArray.length + overflowDaysLastMonth.length;
        let currentRows = Number((currentCols / 7).toFixed(2));
        let expectedRows = Math.ceil(currentRows);
        let expectedCols = expectedRows * 7;

        this.overflowDaysNextMonth = expectedCols - currentCols;
        this.overflowDaysLastMonth = overflowDaysLastMonth;
        this.datePickerDaysInMonth = daysArray;
      },
      setDatePickerFormatDate(startDate, endDate) {
        let _startDate = null;
        let _endDate = null;

        if (startDate) {
          _startDate = this.datePickerFormatDate(startDate);
        }

        if (endDate) {
          _endDate = this.datePickerFormatDate(endDate);

          this.datePickerValue = `${_startDate} — ${_endDate}`;

          return;
        }

        this.datePickerValue = _startDate ? `${_startDate}` : "";
      },
      datePickerFormatDate(date) {
        let formattedDay = this.datePickerDays[date.getDay()];
        let formattedDate = ("0" + date.getDate()).slice(-2); // appends 0 (zero) in single digit date
        let formattedMonth = this.datePickerMonthNames[date.getMonth()];
        let formattedMonthShortName = this.datePickerMonthNames[
          date.getMonth()
        ].substring(0, 3);
        let formattedMonthInNumber = (
          "0" +
          (parseInt(date.getMonth()) + 1)
        ).slice(-2);
        let formattedYear = date.getFullYear();

        let formattedHours = ("0" + (parseInt(date?.getHours()) ?? 0)).slice(
          -2,
        );
        let formattedMinutes = ("0" + parseInt(date?.getMinutes() ?? 0)).slice(
          -2,
        );

        let useFormat = this.datePickerFormat;

        if (
          !this.startDate?.getHours() &&
          !this.startDate?.getMinutes() &&
          !this.endDate?.getHours() &&
          !this.endDate?.getMinutes()
        ) {
          useFormat = useFormat.replace(" H:i");
        }

        switch (useFormat) {
          // Date only
          case "M d, Y":
            return `${formattedMonthShortName} ${formattedDate}, ${formattedYear}`;
          case "MM-DD-YYYY":
            return `${formattedMonthInNumber}-${formattedDate}-${formattedYear}`;
          case "DD-MM-YYYY":
            return `${formattedDate}-${formattedMonthInNumber}-${formattedYear}`;
          case "DD.MM.YYYY":
            return `${formattedDate}.${formattedMonthInNumber}.${formattedYear}`;
          case "YYYY-MM-DD":
            return `${formattedYear}-${formattedMonthInNumber}-${formattedDate}`;
          case "D d M, Y":
            return `${formattedDay} ${formattedDate} ${formattedMonthShortName} ${formattedYear}`;

          // Date time only
          case "M d, Y H:i":
            return `${formattedMonthShortName} ${formattedDate}, ${formattedYear} ${formattedHours}:${formattedMinutes}`;
          case "MM-DD-YYYY H:i":
            return `${formattedMonthInNumber}-${formattedDate}-${formattedYear} ${formattedHours}:${formattedMinutes}`;
          case "DD-MM-YYYY H:i":
            return `${formattedDate}-${formattedMonthInNumber}-${formattedYear} ${formattedHours}:${formattedMinutes}`;
          case "DD.MM.YYYY H:i":
            return `${formattedDate}.${formattedMonthInNumber}.${formattedYear} ${formattedHours}:${formattedMinutes}`;
          case "YYYY-MM-DD H:i":
            return `${formattedYear}-${formattedMonthInNumber}-${formattedDate} ${formattedHours}:${formattedMinutes}`;
          case "D d M, Y H:i":
            return `${formattedDay} ${formattedDate} ${formattedMonthShortName} ${formattedYear} ${formattedHours}:${formattedMinutes}`;

          default:
            return `${formattedMonth} ${formattedDate}, ${formattedYear}`;
        }
      },
    };
  },
);
