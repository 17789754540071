import "./unordered-list/item";
import "./unordered-list/option";

Alpine.data("unorderedList", (modelName) => {
  let _this;
  return {
    index: null,
    selected: null,
    itemsWithValues: null,
    init() {
      _this = this;

      this.index = 0; // Otherwise it would stack on Pagination

      if (modelName !== undefined && modelName !== "") {
        this.selected = this.$wire.get(modelName);
      }
    },
    setValue(e, value) {
      logger.info(null, `Selected ${modelName} Value: ${value}`);

      _this.selected = value;

      this.$wire.$set(modelName, value, true);
    },
  };
});
