Alpine.data("multiselectSelectedItemTemplate", () => {
  return {
    removeSelectedItem() {
      let modelId = Number(this.$el.getAttribute("model-id"));

      let modelIds = this.$wire
        .$get(this.selectedWireModel)
        .filter((item) => item !== modelId);

      this.$wire.$set(this.selectedWireModel, modelIds);
    },
  };
});
