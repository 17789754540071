Alpine.data("multiselectItem", () => {
  return {
    init() {
      if (!(this.selectedItems instanceof Promise)) {
        return;
      }

      let modelId = Number(this.$el.getAttribute("model-id"));

      this.selectedItems
        .then((selected) => {
          Object.entries(selected).forEach(([key, item]) => {
            if (item.id === modelId) {
              this.$el.remove();
            }
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addSelectableItem() {
      let modelId = Number(this.$el.getAttribute("model-id"));

      let modelIds = this.$wire.$get(this.selectedWireModel);

      modelIds.push(modelId);

      this.$wire.$set(this.selectedWireModel, modelIds);

      this.$el.remove();

      this.searchInput = "";
    },
  };
});
