import { getWireModelNameFromElement } from "./helpers/livewire";
import { rgbToHex, hsvToRgb, rgbToHsv, getContrastYIQ, hexToRgba } from "./helpers/colors";

Alpine.data("colorPicker", () => {
  return {
    rgb: { r: 95, g: 163, b: 178 },
    open: false,
    contrast: 1, // Standardwert für Kontrast
    hue: 180,
    picker: { x: 50, y: 50 },
    isDragging: false,
    rgbaColor: '',
    hexColor: '',
    hexBgRgb: '',
    hexBackground: '',
    rectWidth: 100,
    rectHeight: 100,

    get huePosition() {
      return (this.hue / 360) * 100;
    },

    get contrastPosition() {
      return ((this.contrast - 0.5) / 1.5) * 100;
    },

    init() {
      this.updateColor();

      this.$wire.$watch(getWireModelNameFromElement(this.$refs.colorInput), (updated) => {
        this.hexColor = updated;
        this.hexBgRgb = hexToRgba(getContrastYIQ(this.hexColor), 0.75);

        this.setColorFromHex(this.hexColor);
      });
    },

    setColorFromHex(hex) {
      const rgb = hexToRgba(hex);

      if (rgb) {
        this.rgb = rgb;
        this.updatePickerFromRgb(rgb);
      }
    },

    updatePickerFromRgb({ r, g, b }) {
      const { h, s, v } = rgbToHsv(r, g, b);
      this.hue = h;
      this.picker.x = s * (this.rectWidth || 100);
      this.picker.y = (1 - v) * (this.rectHeight || 100);
      this.updateColor();
    },

    startDrag() {
      this.isDragging = true;
    },

    setColor(event) {
      this.startDrag();
      this.setPickerPosition(event);
    },

    dragColor(event) {
      if (this.isDragging) {
        this.setPickerPosition(event);
      }
    },

    setPickerPosition(event) {
      const rect = event.target.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      this.picker.x = Math.min(Math.max(0, x), rect.width);
      this.picker.y = Math.min(Math.max(0, y), rect.height);

      // Speichere die Maße für spätere Verwendung
      this.rectWidth = rect.width;
      this.rectHeight = rect.height;

      this.updateColor();
    },

    dragHue(event) {
      if (this.isDragging) {
        this.setHue(event);
      }
    },

    setHue(event) {
      this.startDrag();
      const rect = event.target.getBoundingClientRect();
      const x = event.clientX - rect.left;
      this.hue = Math.min(360, Math.max(0, (x / rect.width) * 360));
      this.updateColor();
    },

    dragContrast(event) {
      if (this.isDragging) {
        this.setContrast(event);
      }
    },

    setContrast(event) {
      this.startDrag();
      const rect = event.target.getBoundingClientRect();
      const x = event.clientX - rect.left;
      this.contrast = Math.min(2, Math.max(0.5, (x / rect.width) * 1.5 + 0.5)); // Kontrast zwischen 0.5 und 2

      // Aktualisiere den Hintergrund des Colorpickers
      this.updatePickerBackground();

      this.updateColor();
    },

    stopDrag() {
      this.isDragging = false;

      this.$refs.colorInput.dispatchEvent(new Event('input'));
    },

    updateColor() {
      const width = this.rectWidth || 100;
      const height = this.rectHeight || 100;

      const s = this.picker.x / width;
      let v = 1 - this.picker.y / height;

      // Kontrast auf 'v' anwenden
      v = Math.min(1, Math.max(0, ((v - 0.5) * this.contrast) + 0.5));

      const rgb = hsvToRgb(this.hue, s, v);

      this.rgb = rgb;
      this.rgbaColor = `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
      this.$refs.colorInput.value = rgbToHex(rgb.r, rgb.g, rgb.b);
    },

    updatePickerBackground() {
      this.$refs.colorPickerField.style.filter = `contrast(${this.contrast})`;
    },
  };
});
