Alpine.data("slideOver", (id) => {
  //let this;
  return {
    isOpen: false,
    _id: id,
    removeAnimationClassDelay: 4000,
    serverResponseInMaxMs: 2500,
    missClickMainContainerClass: ["animate-shake"],
    missClickCloseButtonContainerClass: [
      "animate-pulse",
      "scale-150",
      "text-red-500",
      "dark:text-red-500",
    ],
    mainContainerClassList: null,
    closeButtonContainerClassList: null,
    expectingResponse: false,
    init() {

   //   this = this;

      this.registerSlideOverEvents();

      this.mainContainerClassList = this.$refs.mainContainer.classList;
      this.closeButtonContainerClassList =
        this.$refs?.closeButtonContainer?.classList ?? '';

      logger.info(
        null,
        `Component: registered\n       ↳ Type → slide-over\n       ↳ Id → ${id}\n       ↳ Listener → slide-over.${id}.state\n\n\n`,
      );
    },
    registerSlideOverEvents() {
      this.$wire.on(`slide-over.${this.getSlideOverId()}.open`, () => {
        this.expectingResponse = false;

        this.isOpen = true;

        this.dispatchState("opened", {});
      });

      this.$wire.on(`slide-over.${this.getSlideOverId()}.close`, () => {
        this.expectingResponse = false;

        this.removeMissClickAnimationFinished();

        this.isOpen = false;

        this.dispatchState("closed", {});
      });
    },
    open() {
      this.dispatchState("open");

      this.expectingResponseHandler(this.isOpen);

      this.isOpen = false;
    },
    close() {
      this.dispatchState("close");

      this.expectingResponseHandler(this.isOpen);

      this.isOpen = false;
    },
    expectingResponseHandler(previousValue) {
      this.expectingResponse = true;

      setTimeout(
        () => {
          if (this.expectingResponse) {
            cmch.throw("WJS1000");

            this.isOpen = previousValue;
          }
        },
        this.serverResponseInMaxMs,
        previousValue,
      );
    },
    dispatchState(newState) {
      let target = `slide-over.${this.getSlideOverId()}.state`;

      this.$dispatch(target, { state: newState });

      logger.info(null, `dispatched "${newState}" to "${target}"`);

      this.updatedAt = Date.now();
    },
    getSlideOverId() {
      if (!this._id) {
        cmch.throw("EAB1000");

        return;
      }

      return this._id;
    },
    removeMissClickAnimationFinished() {
      this.mainContainerClassList.remove(...this.missClickMainContainerClass);
      this.closeButtonContainerClassList.remove(
        ...this.missClickCloseButtonContainerClass,
      );
    },
    startMissClickAnimation() {
      this.mainContainerClassList.add(...this.missClickMainContainerClass);
      this.closeButtonContainerClassList.add(
        ...this.missClickCloseButtonContainerClass,
      );

      setTimeout(
        this.removeMissClickAnimationFinished,
        this.removeAnimationClassDelay,
      );
    },
  };
});
