/********************************************************************
 * slide-over.js
 ********************************************************************/

const initializeSlideOverToggles = function () {
  Array.from(document.getElementsByClassName("slide-over-toggle")).forEach(
    (button) => {
      registerSlideOverOnClickToggleEventListener(button);
    },
  );
};

const initializeLazyLoadedSlideOverToggles = function () {
  Array.from(
    document.getElementsByClassName("lazy-loaded-slide-over-toggle"),
  ).forEach((button) => {
    registerSlideOverOnClickToggleEventListener(button);
  });
};

const registerSlideOverOnClickToggleEventListener = function (button) {
  // remove if already existing
  button.removeEventListener("click", sidebarToggleClick);
  // re/-add sidebarToggleClick Event
  button.addEventListener("click", sidebarToggleClick);
};

const sidebarToggleClick = function (event) {
  let qualifiedName =
    event.currentTarget.getAttribute("slide-over-target") + "-input";

  let target = document.getElementById(qualifiedName);

  if (null === target) {
    console.log("Target not found through qualified name: " + qualifiedName);
    return null;
  }

  target.click();
};

document.addEventListener("livewire:navigated", initializeSlideOverToggles);
document.addEventListener("livewire:initialized", initializeSlideOverToggles);

window.initializeLazyLoadedSlideOverToggles =
  initializeLazyLoadedSlideOverToggles;
