Alpine.data("sectionToggleable", (isToggled) => {
  return {
    showSectionContent: !!isToggled,
    init() {
      this.$el.addEventListener("toggled", (event) => this.onToggledEvent(event));
    },
    onToggledEvent(event) {
      this.showSectionContent = event.detail;
    },
  };
});
