import "./section/toggleable";

Alpine.data("section", () => {
  return {
    durationBase: 20,
    display: false,
    zIndex: 200,
    init() {
      // Display immediately when an currentCardIndex is not available
      if (typeof this.currentCardIndex === "undefined") {
        this.show();
        return;
      }

      // Display delay determined by the specified index,
      // applied after the given timeout.
      setTimeout(() => {
        this.show();
        this.reset();
      }, this.currentCardIndex * this.durationBase);

      this.currentCardIndex = this.currentCardIndex + 1;
      this.zIndex = this.zIndex - this.currentCardIndex * 10;
    },
    show() {
      this.display = true;
    },
    reset() {
      if (this.currentCardIndex !== 0) {
        this.currentCardIndex = 0;
      }
    },
  };
});
