import "./dropdown/item";
import "./dropdown/icon-item";

Alpine.data("dropdown", () => {
  let _this;
  return {
    isOpen: false,
    emptyClass: "",
    cursorPosition: { x: 0, y: 0 },
    init() {
      _this = this;

      this.$refs.toggle.addEventListener("mousemove", (e) =>
        this.updateCursorPosition(e),
      );

      this.emptyClass = this.$el.getAttribute("empty");

      let isEmpty =
        this.$el.getElementsByClassName("dropdown-item").length === 0;

      if (isEmpty && this.emptyClass !== "") {
        this.$el.classList.add(this.emptyClass);
      }
    },
    updateCursorPosition(e) {
      if (!this.isOpen) {
        this.cursorPosition.x = e.clientX;
        this.cursorPosition.y = e.clientY;
      }

      this.setPosition();
    },
    toggle() {
      if (this.isOpen) {
        this.close();

        return;
      }

      this.show();
    },
    show() {
      if (this.cursorPosition.y === 0) {
        this.$refs.container.style.bottom =
          window.innerWidth < breakpoints.sm ? "112px" : "96px";
      }

      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    setPosition() {
      let windowSize = this.getWindowSize();
      let toggleRect = this.getToggleRect();
      let containerRect = this.getContainerRect();

      let smallScreenWidthThreshold = windowSize.width - containerRect.width;
      let offset = 0;

      if (smallScreenWidthThreshold < containerRect.width) {
        offset = containerRect.width - smallScreenWidthThreshold;

        if (offset > smallScreenWidthThreshold) {
          offset = toggleRect.left;
        }
      }

      let cursorOffset = {
        x:
          windowSize.width -
          toggleRect.left -
          (windowSize.width - this.cursorPosition.x),
        y:
          windowSize.height -
          toggleRect.top -
          (windowSize.height - this.cursorPosition.y),
      };

      let left = 16;
      let top = cursorOffset.y + toggleRect.top;

      if (windowSize.width > breakpoints.xl) {
        left = toggleRect.width * 0.5 + offset + cursorOffset.x;
        top = cursorOffset.y - toggleRect.height * 0.5;
      }

      this.$refs.container.style.left = `${left}px`;
      this.$refs.container.style.top = `${top}px`;
    },
    getWindowSize() {
      return { width: window.innerWidth, height: window.innerHeight };
    },
    getContainerRect() {
      return this.$refs.container.getBoundingClientRect();
    },
    getToggleRect() {
      return this.$refs.toggle.getBoundingClientRect();
    },
  };
});
