import "./button/dropdown";

Alpine.data("button", () => {
  return {
    isFirst: false,
    isLast: false,
    init() {
      if (typeof this.currentItemIndex !== "undefined") {
        if (this.currentItemIndex !== 0) {
          // ToDo lol
        }
      }
    },
  };
});
