Alpine.data("toggle", () => {
  let _this;
  return {
    input: null,
    toggle: null,
    wireModel: null,
    checked: false,
    init() {
      _this = this;

      // Input Events (The hidden one)
      this.$refs.input.addEventListener("click", this.onInputClickEvent);

      // Toggle Events (The visual one)
      this.$el.addEventListener("click", this.onToggleClickEvent);

      this.wireModel = this.$refs.input.getAttribute("wire:model");

      if (this.wireModel) {
        let initState = this.$wire.$get(this.wireModel);

        this.setCheckedState(initState);

        this.$wire.$watch(this.wireModel, (checked) => {
          _this.setCheckedState(checked);
        });
      }
    },
    onToggleClickEvent(e) {
      e.preventDefault();

      let checked = !_this.checked;

      if (_this.wireModel) {
        _this.$wire.$set(_this.wireModel, checked);

        return;
      }

      _this.setCheckedState(checked);
    },
    setCheckedState(checked) {
      _this.checked = checked;

      _this.$refs.input.checked = _this.checked;

      _this.$dispatch("toggled", _this.checked);
    },
    onInputClickEvent(e) {
      e.preventDefault();

      console.error(
        "You are not a member of the KI6J coders, this incident will be reported! ;)",
      );
    },
  };
});
