window.timeStampSlider = function (timeRecordStamps) {
  return {
    stamps: timeRecordStamps,
    maxTimeStamp: null,
    minTimeStamp: null,
    highestStampIndex: 0,
    timeStampDragActive: false,
    timeStampDragIndex: null,
    selectedStampIndex: null,
    selectedStampTimestamp: null,
    stampCanBeDeleted: false,

    init() {
      this.setMinMaxTimestamps();
      this.updateModelInput();
    },

    updateModelInput() {
      const input = document.getElementById("stampsInput");
      input.value = JSON.stringify(this.stamps);
      input.dispatchEvent(new Event("input"));
    },

    setMinMaxTimestamps() {
      this.highestStampIndex = this.stamps.length - 1;
      this.minTimeStamp = this.stamps[0].timestamp;
      this.maxTimeStamp = this.stamps[this.highestStampIndex].timestamp;
    },

    calculateLeftOffset(timeStamp, index) {
      let initialTimeStamp = this.stamps[0].timestamp;
      let interval = timeStamp.timestamp - initialTimeStamp;
      return (interval / (this.maxTimeStamp - initialTimeStamp)) * 100;
    },

    calculateWidth(prevTimeStamp, currTimeStamp) {
      if (!prevTimeStamp || !currTimeStamp) return 0;
      let interval = currTimeStamp.timestamp - prevTimeStamp.timestamp;
      return (interval / (this.maxTimeStamp - this.stamps[0].timestamp)) * 100;
    },

    isPause(index) {
      if (index === 0 || index === this.stamps.length - 2) return false;
      return (
        this.stamps[index].type === "pause" &&
        this.stamps[index + 1].type === "resume"
      );
    },

    startTimeStampDrag(index) {
      this.timeStampDragActive = true;
      this.timeStampDragIndex = index;
    },

    mouseMove(event) {
      if (!this.timeStampDragActive || this.timeStampDragIndex === null) return;
      let sliderElement = this.$refs.slider;
      let sliderBounds = sliderElement.getBoundingClientRect();
      let newOffset = (event.clientX - sliderBounds.left) / sliderBounds.width;
      let newTimestamp =
        this.stamps[0].timestamp +
        newOffset * (this.maxTimeStamp - this.stamps[0].timestamp);

      let delta = newTimestamp - this.stamps[this.timeStampDragIndex].timestamp;

      // Wenn der Punkt nach vorne verschoben wird, sicherstellen, dass er nicht mit dem nächsten Punkt überschneidet
      if (
        delta > 0 &&
        this.stamps[this.timeStampDragIndex + 1] &&
        newTimestamp > this.stamps[this.timeStampDragIndex + 1].timestamp
      ) {
        delta =
          this.stamps[this.timeStampDragIndex + 1].timestamp -
          this.stamps[this.timeStampDragIndex].timestamp -
          1;
      }

      // Wenn der Punkt nach hinten verschoben wird, sicherstellen, dass er nicht mit dem vorherigen Punkt überschneidet
      if (
        delta < 0 &&
        this.stamps[this.timeStampDragIndex - 1] &&
        newTimestamp < this.stamps[this.timeStampDragIndex - 1].timestamp
      ) {
        delta =
          this.stamps[this.timeStampDragIndex - 1].timestamp -
          this.stamps[this.timeStampDragIndex].timestamp +
          1;
      }

      // Zeitstempel des bewegten Punkts aktualisieren
      this.stamps[this.timeStampDragIndex].timestamp += delta;

      this.selectedStampTimestamp = this.formatDate(
        this.stamps[this.timeStampDragIndex].timestamp,
      );
    },

    startXStampDrag(index, event) {
      // Start- und Endpunkt können nicht verschoben werden
      if (index === 0 || index === this.stamps.length - 1) {
        return;
      }
      event.preventDefault();
      this.timeStampDragActive = true;
      this.timeStampDragIndex = index;
      window.addEventListener("mousemove", this.mouseMove.bind(this));
      window.addEventListener("mouseup", this.stopTimeStampDrag.bind(this));
    },

    formatTimestamp(timestamp) {
      let date = new Date(timestamp * 1000);
      //const day = date.getDate();
      //const month = date.getMonth() + 1;
      //const year = date.getFullYear();
      let hours = date.getHours();
      let minutes = date.getMinutes();

      if (minutes < 10) {
        minutes = `0${minutes}`;
      }

      if (hours < 10) {
        hours = `0${hours}`;
      }

      //${day}.${month}.${year}
      return `${hours}:${minutes}`;
    },

    formatDate(unixTimeStamp) {
      const date = new Date(unixTimeStamp * 1000);
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    },

    inputTimeStamp() {
      // Konvertieren Sie das Datum vom Eingabeformat in einen Unix-Zeitstempel
      let newStamp = new Date(this.selectedStampTimestamp).getTime() / 1000;

      if (this.selectedStampIndex !== 0 && newStamp <= this.minTimeStamp) {
        console.log("Can't be less or equal than start!");
        return;
      }

      if (
        this.selectedStampIndex !== this.highestStampIndex &&
        newStamp >= this.maxTimeStamp
      ) {
        console.log("Can't be greater or equal than stop!");
        return;
      }

      this.stamps[this.selectedStampIndex].timestamp = newStamp;

      if (this.selectedStampIndex === 0) {
        this.minTimeStamp = newStamp;
        //this.setMinMaxTimestamps();
      }

      if (this.selectedStampIndex === this.highestStampIndex) {
        this.maxTimeStamp = newStamp;
        //this.setMinMaxTimestamps();
      }

      this.updateModelInput();
    },

    selectStampByIndex(index) {
      this.selectedStampIndex = index;

      this.selectedStampTimestamp = this.formatDate(
        this.stamps[index].timestamp,
      );

      if (
        this.selectedStampIndex === 0 ||
        this.selectedStampIndex === this.highestStampIndex
      ) {
        this.stampCanBeDeleted = false;

        return;
      }

      this.stampCanBeDeleted = true;
    },

    deleteSelectedStampIndex() {
      if (
        this.selectedStampIndex === 0 ||
        this.selectedStampIndex === this.stamps.length - 1
      ) {
        // Start- und Endpunkt sollten nicht gelöscht werden
        return;
      }

      if (this.stamps[this.selectedStampIndex].type === "pause") {
        // Wenn auf 'pause'-Punkt geklickt wurde, entfernen Sie diesen und den folgenden 'resume'-Punkt
        this.stamps.splice(this.selectedStampIndex, 2);
      } else if (this.stamps[this.selectedStampIndex].type === "resume") {
        // Wenn auf 'resume'-Punkt geklickt wurde, entfernen Sie diesen und den vorherigen 'pause'-Punkt
        this.stamps.splice(this.selectedStampIndex - 1, 2);
      }

      this.deselectStamp();
      this.setMinMaxTimestamps();
      this.updateModelInput();
    },

    deselectStamp() {
      this.selectedStampIndex = null;
      this.stampCanBeDeleted = false;
    },

    insertStamps(event) {
      if (this.timeStampDragActive) {
        return;
      }

      let sliderBounds = event.target.getBoundingClientRect();
      let clickXOffset = event.clientX - sliderBounds.left;
      let clickTimeStampPause =
        this.stamps[0].timestamp +
        (clickXOffset *
          (this.stamps[this.stamps.length - 1].timestamp -
            this.stamps[0].timestamp)) /
          sliderBounds.width;
      let clickTimeStampResume = clickTimeStampPause + 1; // Fügen Sie hier den gewünschten Offset hinzu

      let insertIndex = this.stamps.findIndex((stamp, index) => {
        return (
          index < this.stamps.length - 1 &&
          this.stamps[index].timestamp <= clickTimeStampPause &&
          clickTimeStampPause <= this.stamps[index + 1].timestamp
        );
      });

      // Überprüfen, ob zwischen den beiden gefundenen Stempeln bereits ein Paar Stempel vorhanden ist
      // und ob der Punkt zwischen einem 'pause' und 'resume' Punkt ist
      if (
        insertIndex !== -1 &&
        !(this.isPause(insertIndex) && !this.isPause(insertIndex + 1)) &&
        !(
          this.stamps[insertIndex].type === "pause" &&
          this.stamps[insertIndex + 1].type === "resume"
        )
      ) {
        this.stamps.splice(
          insertIndex + 1,
          0,
          { timestamp: clickTimeStampPause, type: "pause" },
          { timestamp: clickTimeStampResume, type: "resume" },
        );
      }

      this.setMinMaxTimestamps();
      this.updateModelInput();
    },

    stopTimeStampDrag() {
      this.timeStampDragActive = false;
      window.removeEventListener("mousemove", this.mouseMove);
      window.removeEventListener("mouseup", this.stopTimeStampDrag);
      this.updateModelInput();
    },
  };
};
