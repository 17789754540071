export default (audioSource) => {
  let _this = null;
  return {
    mimeType: null,
    source: audioSource,
    volumeLevel: 3,
    isPaused: true,
    isMuted: false,
    beforeMutedVolumeSliderValue: 0,
    audioDuration: 0,
    timeTotalDurationString: "00:00",
    timeElapsedString: "00:00",
    init() {
      _this = this;
      this.initializeAudioPlayer();
    },
    registerEventListener() {
      // Since livewire don't reload the page, we manually remove the listeners
      this.$refs.audioPlayer.removeEventListener(
        "ended",
        this.onAudioPlayerEndedEvent,
      );
      this.$refs.audioPlayer.addEventListener(
        "ended",
        this.onAudioPlayerEndedEvent,
      );

      this.$refs.audioPlayer.removeEventListener(
        "loadedmetadata",
        this.onAudioPlayerLoadedMetaDataEvent,
      );
      this.$refs.audioPlayer.addEventListener(
        "loadedmetadata",
        this.onAudioPlayerLoadedMetaDataEvent,
      );

      this.$refs.audioPlayer.removeEventListener(
        "timeupdate",
        this.onAudioPlayerTimeUpdateEvent,
      );
      this.$refs.audioPlayer.addEventListener(
        "timeupdate",
        this.onAudioPlayerTimeUpdateEvent,
      );
    },
    initializeAudioPlayer() {
      this.$refs.audioPlayer.volume = 0.5;
      this.$refs.audioPlayer.setAttribute("src", this.source.url);
      this.$refs.audioPlayer.setAttribute("type", this.source.mime_type);

      this.registerEventListener();
    },
    onAudioPlayerEndedEvent() {
      _this.stop();
    },
    onAudioPlayerTimeUpdateEvent() {
      let currentTime = _this.$refs.audioPlayer.currentTime;

      let time = _this.formatTime(Math.round(currentTime));

      _this.timeElapsedString = `${time.minutes}:${time.seconds}`;

      _this.$refs.audioDurationSlider.value = currentTime;
    },
    onAudioPlayerLoadedMetaDataEvent(event) {
      _this.audioTotalDuration = event.target.duration;

      _this.$refs.audioDurationSlider.setAttribute(
        "max",
        _this.audioTotalDuration,
      );

      let time = _this.formatTime(Math.round(_this.audioTotalDuration));

      _this.timeTotalDurationString = `${time.minutes}:${time.seconds}`;
    },
    play() {
      this.isPaused = false;

      this.$refs.audioPlayer.play();
    },
    pause() {
      this.isPaused = true;
      this.$refs.audioPlayer.pause();
    },
    stop() {
      this.pause();
      this.$refs.audioDurationSlider.value = 0;
      this.$refs.audioPlayer.currentTime = 0;
    },
    mute() {
      this.beforeMutedVolumeSliderValue = this.$refs.audioVolumeSlider.value;
      this.$refs.audioVolumeSlider.value = 0;
      this.setVolume(0);
      this.volumeLevel = 0;
      this.isMuted = true;
    },
    unmute() {
      this.$refs.audioVolumeSlider.value = this.beforeMutedVolumeSliderValue;
      this.setVolume(this.$refs.audioVolumeSlider.value);
      this.calculateVolumeLevel();
      this.isMuted = false;
    },
    togglePlayPause() {
      if (this.isPaused) {
        this.play();
        return;
      }
      this.pause();
    },
    toggleMute() {
      this.isMuted ? this.unmute() : this.mute();
    },
    calculateVolumeLevel() {
      this.volumeLevel = Math.ceil(this.$refs.audioVolumeSlider.value / 3 / 10);
    },
    setCurrentDurationTime(duration) {
      _this.$refs.audioPlayer.currentTime = duration;
    },
    setVolume(volumeLevel) {
      // Takes int or float between 0.0 and 1.0 or 1 and 100
      this.$refs.audioPlayer.volume =
        volumeLevel > 1 ? volumeLevel / 100 : volumeLevel;

      this.calculateVolumeLevel();
    },
    onDurationSliderChangeEvent() {
      this.setCurrentDurationTime(this.$refs.audioDurationSlider.value);
    },
    onVolumeSliderChangeEvent() {
      this.setVolume(this.$refs.audioVolumeSlider.value);
    },
    formatTime(timeInSeconds) {
      this.result = new Date(timeInSeconds * 1000)
        .toISOString()
        .substring(11, 19);

      return {
        minutes: this.result.substring(3, 5),
        seconds: this.result.substring(6, 8),
      };
    },
  };
};
