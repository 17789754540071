/**
 * Retrieves the title of the current web page.
 *
 * @returns {string} The title of the web page.
 */
const getPageTitle = () => {
  return document.title;
};

/**
 * Sets the page title.
 *
 * @param {string} title - The title of the page.
 *
 * @returns {void}
 */
const setPageTitle = (title) => {
  document.title = title;
};

/**
 * Creates a Time Tracker object.
 *
 * @param {Object} stamps
 * @returns {Object} - The Time Tracker object.
 */
export default () => {
  return {
    initialDateTime: null,
    currentEvent: null,
    pausedTime: 0,
    pauseStart: null,
    hours: "00",
    minutes: "00",
    seconds: "00",
    totalHours: "00",
    totalMinutes: "00",
    totalSeconds: "00",
    pageTitleRegex: /\d{2}:\d{2}:\d{2}/,
    timerInterval: null,
    offsetInSeconds: 0,
    init() {
      this.offsetInSeconds = new Date().getTimezoneOffset() * 60;
      this.processTimestamps(); // Process the timestamps on initialisation.
      this.tick();
     this.timerInterval = setInterval(() => {
        this.processLatestStamp();
        this.tick();
      }, 1000);
    },

    processTimestamps() {
      //const entries = Object.entries(); // Convert timestamps object to an array of entries [key, value].

      // entries.sort((a, b) => a[0] - b[0]);  // Sort array by keys (date) in ascending order.

      //  console.log(entries);
      // Extract the initial event.


      const initialEntry = this.timestamps[0]["timestamp"];

      if (initialEntry) {
        this.initialDateTime = initialEntry * 1000;
      }
    },
    processLatestStamp() {
      let latestStamp = this.timestamps[this.timestamps.length-1];

      if (latestStamp) {
        let timestamp = new Date(latestStamp.timestamp).getDate();// * 1000; // Get the latest timestamp.
        this.currentEvent = latestStamp.stamp_type; // Get the latest event.

        switch (this.currentEvent) {
          case "pause":
            this.pauseStart = timestamp;
            break;
          case "resume":
            if (this.pauseStart) {
              this.pausedTime += timestamp - this.pauseStart;
              this.pauseStart = null;
            }
            break;
        }
      }
    },
    tick() {
      let elapsedTimeTotal = Date.now() - this.initialDateTime;
      let elapsedTime = elapsedTimeTotal - this.pausedTime;

      if (this.currentEvent !== "pause") {
        let hours = Math.floor(elapsedTime / (1000 * 60 * 60));
        let minutes = Math.floor(
          (elapsedTime % (1000 * 60 * 60)) / (1000 * 60),
        );
        let seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);
        this.hours = hours.toString().padStart(2, "0");
        this.minutes = minutes.toString().padStart(2, "0");
        this.seconds = seconds.toString().padStart(2, "0");
      }

      let totalHours = Math.floor(elapsedTimeTotal / (1000 * 60 * 60));
      let totalMinutes = Math.floor(
        (elapsedTimeTotal % (1000 * 60 * 60)) / (1000 * 60),
      );
      let totalSeconds = Math.floor((elapsedTimeTotal % (1000 * 60)) / 1000);
      this.totalHours = totalHours.toString().padStart(2, "0");
      this.totalMinutes = totalMinutes.toString().padStart(2, "0");
      this.totalSeconds = totalSeconds.toString().padStart(2, "0");

      this.updatePageTitle();
    },
    getPageTitleEmoji() {
      switch (this.currentEvent) {
        case "pause":
          return "⏸️";
        default:
          return "🔴";
      }
    },
    updatePageTitle() {
      let timeOnly = getPageTitle()
        .replace(
          this.pageTitleRegex,
          `${this.hours}:${this.minutes}:${this.seconds}`,
        )
        .substring(2);
      setPageTitle(`${this.getPageTitleEmoji()} ${timeOnly}`);
    },
  };
};
