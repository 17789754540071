document.addEventListener("livewire:init", () => {
  Alpine.data("modal", (modalId) => {
    return {
      showModal: false,
      init() {

        const listener = `modal.${modalId}`;

        logger.info(null, `Listening on: ${listener}`);

        this.$wire.on(listener, (request) => {
          this.showModal = request.open;
        });
      },
      closeModal() {
        this.showModal = false;
      }
    };
  });
});