Alpine.data("dialog", (id) => {
  let _this;
  return {
    _id: null,
    showDialog: false,
    title: "#TITLE",
    message: "#MESSAGE",
    options: [],
    firstOption: [],
    icon: "",
    delayClosedDispatchMessageInMs: 1000,
    awaitingResponse: false,
    init() {
      _this = this;

      this.$wire.on(`dialog.${this.getDialogId()}.open`, (data) =>
        this.open(data),
      );
      this.$wire.on(`dialog.${this.getDialogId()}.close`, (data) =>
        this.close(data),
      );

      logger.info(
        null,
        `Component: registered\n       ↳ Type → dialog\n       ↳ Id → ${_this.getDialogId()}\n       ↳ Listener → dialog.${_this.getDialogId()}.event\n\n\n`,
      );
    },
    close(data) {
      logger.info(null, `dialog request to close dialog.`, data);

      _this.awaitingResponse = false;

      _this.showDialog = false;

      _this.dispatchEvent("closed", data);
    },
    open(data) {
      _this.icon = data.icon;
      _this.title = data.title;
      _this.message = data.message;
      _this.options = data.options;

      // Extract a first option, to prevent styling issue in the dialog-first element
      if (_this.options.length > 0) {
        _this.firstOption = _this.options.shift();
      }

      logger.info(null, `dialog request to open dialog.`, _this.firstOption);

      _this.awaitingResponse = false;

      _this.showDialog = true;

      _this.dispatchEvent("opened", {});
    },
    select(eventName, parameter = {}) {
      // Close the visual dialog, it will be reopened as soon as the
      // answer from the server tells us to do so, because it reappears
      // within a new request
      _this.showDialog = false;

      logger.info(null, `dialog option ${eventName} was selected.`, parameter);

      if (!eventName) {
        _this.close([]);

        return;
      }

      if (_this.awaitingResponse) {
        cmch.throw("WUI1002");

        return;
      }

      _this.awaitingResponse = true;

      _this.dispatchEvent(eventName, parameter);
    },
    dispatchEvent(eventName, data = {}) {
      let target = `dialog.${_this.getDialogId()}.event`;

      let param = Object.assign({ eventName: eventName }, { data: data });

      _this.$dispatch(target, param);

      logger.info(
        null,
        `dispatched event "${eventName}" to "${target}"`,
        param,
      );
    },
    getDialogId() {
      if (!_this._id) {
        _this._id = id === '' ? _this.$wire.$get("_dialogId") : id;

        if (!_this._id) {
          cmch.throw("EAB1000");

          return;
        }
      }

      return _this._id;
    },
  };
});
