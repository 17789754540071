Alpine.data("comboboxItem", (selectable) => {
  let _this = this;
  return {
    label: null,
    selectable: !!selectable,
    value: null,
    init() {
      _this = this;

      this.label = this.$el.getAttribute("label");

      this.value = this.$el.getAttribute("value");

      if (this.valueInput !== null && this.valueInput === this.value) {
        this.select();

        return;
      }

      if (_this.$el.getAttribute("selected")) {
        this.select();
      }
    },
    select() {
      this.visualInput = this.label;

      this.unsaved = false; // ensure updating is allowed

      this.valueInput = this.value !== "" ? this.value : this.label;

      this.originalVisualInputSelection = this.label;

      this.isOpen = false;
    },
  };
});
