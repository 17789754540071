document.addEventListener("alpine:init", () => {
  Alpine.store("confirmationDialog", {
    title: "",
    description: "",
    button: {
      confirm: "",
      cancel: "",
    },
    show: false,
    cleanContent(content) {
      // is Array
      if (Array.isArray(content)) {
        return content;
      }
      // is string
      if (typeof content !== "string") {
        return ["UNKNOWN_TITLE", ""];
      }
      // has semicolon separator
      if (content.includes(";")) {
        return content.split(";");
      }
      // is only title
      return [content, ""];
    },
    showDialog(content) {
      let _content = this.cleanContent(content);
      this.title = _content[0];
      this.description = _content[1];
      this.button.confirm = _content[2];
      this.button.cancel = _content[3];
      this.show = true;
    },
    hideDialog() {
      this.title = "";
      this.description = "";
      this.button.confirm = "";
      this.button.cancel = "";
      this.show = false;
    },
  });
});

const waitUserChoiceSelection = async function () {
  const timeout = async (ms) => new Promise((res) => setTimeout(res, ms));

  const handleChoiceButtonClick = (button) =>
    (choice = button.currentTarget.getAttribute("confirmation-dialog-choice"));

  let choice = 0;

  Array.from(
    document.getElementsByClassName("confirmation-dialog-button"),
  ).forEach((button) =>
    button.addEventListener("click", handleChoiceButtonClick),
  );

  while (choice === 0) await timeout(150);

  Array.from(
    document.getElementsByClassName("confirmation-dialog-button"),
  ).forEach((button) =>
    button.removeEventListener("click", handleChoiceButtonClick),
  );

  return choice === "1";
};

const showConfirmationDialog = async function (content) {
  Alpine.store("confirmationDialog").showDialog(content);

  let result = await waitUserChoiceSelection();

  Alpine.store("confirmationDialog").hideDialog();

  return result;
};

// noinspection JSUnresolvedReference
Livewire.directive("confirmation", ({ el, directive, component, cleanup }) => {
  let onClick = (e) => {
    e.stopImmediatePropagation();

    e.preventDefault();

    showConfirmationDialog(directive.expression).then((confirmed) => {
      let attribute = confirmed
        ? "wire:confirmation.confirmed"
        : "wire:confirmation.canceled";

      let target = el.getAttribute(attribute)?.split(";");

      if (target) component.$wire.call(target[0], target[1]?.split(","));
    });
  };

  el.addEventListener("click", onClick, { capture: true });

  // Register any cleanup code inside `cleanup()` in the case
  // where a Livewire component is removed from the DOM while
  // the page is still active.
  cleanup(() => {
    el.removeEventListener("click", onClick);
  });
});
