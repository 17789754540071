// noinspection JSUnusedGlobalSymbols

/**
 * Builds a table layout using given fields and layout.
 *
 * @param {array} fields - The array of field objects to be used in the table.
 * @param {array} layout - The initial layout of the table.
 * @return {object} - The table builder object.
 */
window.tableBuilder = function (fields, layout) {
  return {
    layout: layout,
    builderLayout: null,
    fields: fields,
    itemOnDrag: null,
    itemFromRow: null,
    itemColumnIndex: null,
    init() {
      this.buildBuilderLayout();
    },
    buildLayout() {
      this.layout = this.builderLayout
        .map((obj) => obj.map((sub) => sub.field))
        .filter((obj) => obj.length > 0);
    },
    buildBuilderLayout() {
      this.builderLayout = this.layout.map((innerArray) => {
        return innerArray.map((key) => {
          let matchingFieldObject = this.fields.find(
            (fieldObject) => fieldObject.field === key,
          );
          return matchingFieldObject ? matchingFieldObject : key;
        });
      });
    },
    addRow() {
      this.builderLayout.push([]);
    },
    deleteRow(targetRow) {
      this.builderLayout.splice(targetRow, 1);
    },
    addColumn(event, targetRow) {
      if (targetRow !== this.itemFromRow) {
        this.builderLayout[targetRow].push(this.itemOnDrag);
      }
      if (this.itemFromRow !== targetRow && this.itemColumnIndex !== null) {
        this.removeColumn(event, this.itemFromRow);
      }
      this.buildLayout();
    },
    removeColumn(event, targetRow) {
      if (this.itemFromRow !== null) {
        this.builderLayout[this.itemFromRow].splice(targetRow, 1);
      }
    },
    dropSelf(event, dropColumnIndex, dropRow) {
      event.preventDefault();
      if (dropRow === this.itemFromRow) {
        const row = this.builderLayout[this.itemFromRow];
        const item = row.splice(this.itemColumnIndex, 1)[0];
        row.splice(dropColumnIndex, 0, item);
        this.builderLayout = [...this.builderLayout];
      }
    },
    dragstartSelf(event, columnIndex, itemFromRow) {
      this.itemFromRow = itemFromRow;
      this.itemColumnIndex = columnIndex;
      this.itemOnDrag = JSON.parse(event.target.getAttribute("field"));
      event.dataTransfer.setData("text/plain", "");
    },
  };
};
