export default (sources) => {
  return {
    sources: sources,
    playing: false,
    controls: true,
    muted: false,
    muteForced: false,
    fullscreen: false,
    ended: false,
    mouseleave: false,
    autoHideControlsDelay: 3000,
    controlsHideTimeout: null,
    poster: null,
    videoDuration: 0,
    timeDurationString: "00:00",
    timeElapsedString: "00:00",
    showTime: false,
    volume: 1,
    volumeBeforeMute: 1,
    videoPlayerReady: false,
    init() {
      let supportsVideo = document.createElement("video").canPlayType;

      if (!supportsVideo) {
        console.error(
          "Your browser does not supports the Alution Video Player, please change your browser to a modern one!",
        );
      }

      this.$refs.player.load();
      // Hide the default player controls
      this.$refs.player.controls = false;
      this.$refs.blurredPlayer.controls = false;
      this.$refs.blurredPlayer.muted = true;

      this.$watch("playing", (value) => {
        if (value) {
          this.ended = false;
          this.controlsHideTimeout = setTimeout(() => {
            this.controls = false;
          }, this.autoHideControlsDelay);
        } else {
          clearTimeout(this.controlsHideTimeout);
          this.controls = true;
        }
      });

      if (!document?.fullscreenEnabled) {
        this.$refs.fullscreenButton.style.display = "none";
      }

      document.addEventListener("fullscreenchange", (e) => {
        this.fullscreen = !!document.fullscreenElement;
      });
    },
    timelineSeek(e) {
      this.time = this.formatTime(Math.round(e.target.value));
      this.timeElapsedString = `${this.time.minutes}:${this.time.seconds}`;
    },
    metaDataLoaded(event) {
      this.videoDuration = event.target.duration;
      this.$refs.videoProgress.setAttribute("max", this.videoDuration);

      this.time = this.formatTime(Math.round(this.videoDuration));
      this.timeDurationString = `${this.time.minutes}:${this.time.seconds}`;
      this.showTime = true;
      this.videoPlayerReady = true;
    },
    togglePlay(e) {
      if (this.$refs.player.paused || this.$refs.player.ended) {
        this.playing = true;
        this.$refs.player.play();
        this.$refs.blurredPlayer.play();
      } else {
        this.$refs.player.pause();
        this.$refs.blurredPlayer.pause();
        this.playing = false;
      }
    },
    toggleMute() {
      this.muted = !this.muted;
      this.$refs.player.muted = this.muted;
      if (this.muted) {
        this.volumeBeforeMute = this.volume;
        this.volume = 0;
      } else {
        this.volume = this.volumeBeforeMute;
      }
    },
    timeUpdatedInterval() {
      if (!this.$refs.videoProgress.getAttribute("max")) {
        this.$refs.videoProgress.setAttribute(
            "max",
            this.$refs.player.duration,
        );
      }

      this.$refs.videoProgress.value = this.$refs.player.currentTime;
      this.time = this.formatTime(Math.round(this.$refs.player.currentTime));
      this.timeElapsedString = `${this.time.minutes}:${this.time.seconds}`;
    },
    updateVolume(e) {
      this.volume = e.target.value;
      this.$refs.player.volume = this.volume;
      if (this.volume === 0) {
        this.muted = true;
      }

      if (this.muted && this.volume > 0) {
        this.muted = false;
      }
    },
    timelineClicked(e) {
      this.rect = this.$refs.videoProgress.getBoundingClientRect();
      this.pos =
        (e.pageX - this.rect.left) / this.$refs.videoProgress.offsetWidth;

      let updatedTime = this.pos * this.$refs.player.duration;
      this.$refs.player.currentTime = updatedTime;
      this.$refs.blurredPlayer.currentTime = updatedTime;
    },
    handleFullscreen() {
      if (document.fullscreenElement !== null) {
        // The document is in fullscreen mode
        document.exitFullscreen();
      } else {
        // The document is not in fullscreen mode
        this.$refs.videoContainer.requestFullscreen();
      }
    },
    mousemoveVideo() {
      if (this.playing) {
        this.resetControlsTimeout();
      } else {
        this.controls = true;
        clearTimeout(this.controlsHideTimeout);
      }
    },
    videoEnded() {
      this.ended = true;
      this.playing = false;
      this.$refs.player.currentTime = 0;
      this.$refs.blurredPlayer.currentTime = 0;
    },
    resetControlsTimeout() {
      this.controls = true;
      clearTimeout(this.controlsHideTimeout);
      let that = this;
      this.controlsHideTimeout = setTimeout(function () {
        that.controls = false;
      }, this.autoHideControlsDelay);
    },
    formatTime(timeInSeconds) {
      this.result = new Date(timeInSeconds * 1000)
        .toISOString()
        .substring(11, 19);

      return {
        minutes: this.result.substring(3, 5),
        seconds: this.result.substring(6, 8),
      };
    },
  };
};
