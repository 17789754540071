import "./combobox/item";

Alpine.data("combobox", (visualWireModel, valueWireModel) => {
  let _this;
  return {
    currentItemIndex: 0,
    visualInput: "",
    valueInput: "",
    originalVisualInputSelection: "",
    unsaved: true,
    isOpen: false,
    hasError: false,
    hasSearchModel: false,
    visualWireModel: visualWireModel, // visual = search as well
    valueWireModel: valueWireModel,
    wireModelName: "",
    errorText: "",
    errorClass: "",
    init() {
      _this = this;

      if (this.valueWireModel !== "") {
        this.valueInput = this.$wire.$get(this.valueWireModel);
        this.$wire.$watch(this.valueWireModel, (value) => {
          Array.from(this.$el.getElementsByClassName("combobox-item")).forEach(
              (comboboxItem) => {
                if (comboboxItem.getAttribute("value") === value?.toString()) {
                  this.visualInput = comboboxItem.getAttribute("label");
                }
              },
          );
        });
      }

      if (this.visualWireModel !== "") {
        this.visualInput = this.$wire.$get(this.visualWireModel);

        this.$wire.$watch(this.visualWireModel, (newValue) => {
          this.visualInput = newValue;
        });
      }

      this.$watch(
          "visualInput",
          (updatedValue) => {
            this.unsaved = true;

            if (_this.visualWireModel === "") {
              return;
            }

            _this.$wire.$set(_this.visualWireModel, updatedValue);
          },
          300,
      );

      this.$watch(
          "valueInput",
          (updatedValue) => {
            this.unsaved = true;

            if (_this.valueWireModel === "") {
              return;
            }

            _this.$wire.$set(_this.valueWireModel, updatedValue);
          },
          300,
      );
    },
    open() {
      if (!this.isOpen) {
        this.isOpen = true;

        this.$refs.visualInput.select();
      }
    },
    close() {
      if (this.isOpen) {
        this.isOpen = false;
      }

      if (this.unsaved) {
        this.visualInput = this.originalVisualInputSelection;
      }
    },
    focus() {
      this.$refs.visualInput.focus();
    },
  };
});
