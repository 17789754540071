Alpine.data("unorderedListOption", (value) => {
  return {
    value: value,
    init() {
      this.$el.addEventListener("click", () => this.onClickEvent());
    },
    onClickEvent(e) {
      this.setValue(e, value);
    },
  };
});
